/* Base styling for the header */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 20px; /* Set font size to 14px */
  transition: background-color 0.3s ease;
}

.dashboard-header.dark {
  background-color: #343a40;
  color: #ffffff;
}

/* Styling for the logo and search bar */
.header-logo {
  flex: 1;
  position: relative;
}

.header-logo input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px; /* Ensure font size is 14px */
  background-color: #ffffff;
  color: #333;
  padding-right: 32px;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

/* Styling for header icons and profile dropdown */
.header-icons {
  display: flex;
  align-items: center;
}

.notifications-icon {
  position: relative;
  margin-right: 20px;
  font-size: 18px; /* Ensure font size is 14px */
  cursor: pointer;
}

.notifications-icon .badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #dc3545;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 18px;
}

.notifications-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 160px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  transform: translateY(-10px); /* Initial transform for animation */
}

.notifications-icon:hover .notifications-dropdown {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0); /* Move into place */
}

.notifications-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notifications-dropdown ul li {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px; /* Ensure font size is 14px */
}

.notifications-dropdown ul li:hover {
  background-color: #f1f1f1;
}

.notifications-dropdown ul li a {
  text-decoration: none;
  color: #333;
  display: block;
}

.notifications-dropdown ul li:last-child {
  border-bottom: none;
}

/* Profile dropdown styling */
.dropdown.profile {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-name {
  font-size: 18px; /* Ensure font size is 14px */
  font-weight: 500;
  margin: 0 10px;
}

/* Animated Dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 160px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  transform: translateY(-10px); /* Initial transform for animation */
}

.dropdown.profile:hover .dropdown-content {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0); /* Move into place */
}

.dropdown-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-content ul li {
  padding: 10px;
  /* border-bottom: 1px solid #e0e0e0; */
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px; /* Ensure font size is 14px */
}

.dropdown-content ul li:hover {
  background-color: #f1f1f1;
  color: black !important;
}

.dropdown-content ul li a {
  text-decoration: none;
  color: #333;
  display: block;
  font-size: 14px; /* Ensure font size is 14px */
}

.dropdown-content ul li:last-child {
  border-bottom: none;
}

.dropdown-content ul li.btn-danger {
  color: ;
  font-size: 14px; /* Ensure font size is 14px */
}

/* Theme toggle button styling */
.theme-toggle {
  background: none;
  border: none;
  font-size: 14px; /* Ensure font size is 14px */
  cursor: pointer;
  margin-left: 20px;
}

/* Greeting styling */
.greeting {
  font-size: 14px; /* Ensure font size is 14px */
  font-weight: 500;
  margin-left: 20px;
}

.left_header_part {
  display: flex;
  align-items: center;
  gap: 10px;
}
.right_header_part h1 {
  font-size: 24px;
  margin: 0;
}