/* Add keyframes for opening and closing animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 123345678 !important;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.modalOverlay.open {
  opacity: 1;
  animation: fadeIn 0.3s ease forwards;
}

.modalContainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9) !important; /* Initial scaling for animation */
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: min(800px, 96%);
  z-index: 10000; /* Ensure the container is above the overlay */
  display: flex;
  z-index: 123345678 !important;
/* min-height: 90vh !important; */
max-height: 90vh !important;
  overflow-y: auto;

  flex-direction: column;
  justify-content: flex-start;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.modalChildren{
  padding-bottom:30px !important;

}

.modalContainer.open {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1); /* Final scaling for animation */
  animation: fadeIn 0.3s ease forwards;
}

.modalContainer.closing {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.9); /* Scale down for closing effect */
  animation: fadeOut 0.3s ease forwards;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modalTitle {
  font-size: 20px;
  font-weight: 700;
  color: var(--primary, var(--main-color));
  font-family: Tajawal;
  line-height: normal;
}

.exitModal {
  cursor: pointer;
}

.modalChildren {
  /* Add any styles you want for the modal content */
}

.popup_agree_btn,
.popup_refuse_btn {
  padding: 8px 16px;
  background-color: var(--main-color);
  margin-top: 20px;
  color: #fff;
  border-radius: 5px;
  margin-left: auto !important;
  border: none;
  cursor: pointer;
}

.popup_refuse_btn {
  background-color: #851709;
}

.modalButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin: 10px 5px;
}



