.welcome-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    
    max-width: 600px;
    margin: 20px 0 6px;
    padding: 0 30px;
}
  
  .welcome-text {
    text-align: center;
    color: #2c3e50;
  }
  
  .wave-emoji {
    font-size: 1.5rem;
    margin-right: 5px;
    vertical-align: middle;
  }
  
  .welcome-text h2 {
    font-size: 1.5rem;
    margin: 2px 0;
    text-align: left;
  }
  
  .welcome-text p {
    font-size: 1rem;
    text-align: start;
    margin: 5px 0;
    color: #34495e;
  }
  