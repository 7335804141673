@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400..800&family=Cairo+Play:wght@200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --main-color: #3d8865;
  --yellow-1: #f59d38;
  --yellow-2: #e8a83f;
  --green-4: #7fb099;
  --green-3: #3d8865;
  --green-2: #3b732d;
  --green-1: #005f3a;

}

body {
  padding: 0;
  margin: 0;
}

* {
  position: relative;
  scroll-behavior: smooth;
  /* direction: rtl; */
  font-family: "Cairo Play", sans-serif !important;

  /* text-align: right; */
}

.loader .box {
  width: 100%;
  height: 100%;
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-color);
}

/* styles add by m_sayed */
/* styles add by m_sayed */
/* styles add by m_sayed */
/* styles add by m_sayed */

.page_padding {
  padding: 30px;
}

.table_row_image {
  width: 40px;
}

.table_row_image img {
  width: 100%;
  object-fit: cover;
}

.title_add {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title_add svg {
  color: #0e6696;
  font-size: 30px;
  width: 30px;
  height: 30px;
  transition: 0.1s ease;
  cursor: pointer;
}

.title_add svg:hover {
  width: 32px;
  height: 32px;
}

.inputs {
  display: flex;
  /* align-items: center; */
  gap: 20px;
  flex-direction: column;
}

.field_input {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

input,
select,
textarea {
  border: none;
  outline: none;
  border-radius: 5px;
  transition: 0.1s ease;
  border: 1px solid rgb(10, 158, 55);
  padding: 5px 4px;
}

input:focus,
select:focus,
textarea:focus {
  border: 1px solid rgb(6, 107, 36);
  box-shadow: 0px 0px 3px 1px #009419ad;
}

.field_input input,
.field_input select,
.field_input textarea {
  border: none;
  outline: none;
  border-radius: 5px;
  transition: 0.1s ease;
  background: transparent !important;
  border: 1px solid rgb(10, 158, 55);
  padding: 12px !important;
}

.field_input input:focus,
.field_input select:focus,
.field_input textarea:focus {
  border: 1px solid rgb(6, 107, 36);
  box-shadow: 0px 0px 3px 1px #009419ad;
}

.toasterContainerTwo {
  z-index: 12312143423234328427344324 !important;
}

.select__input {
  border: none;
  box-shadow: none !important;
}


/* ===================================== New Style ============================= */

.sidebar ul a.active span {
  font-size: 14px !important;
}

.sidebar ul a,
li.category-header {
  width: 87% !important;
  /* min-height: 42px !important; */
  border-radius: 10px !important;
  line-height: 42px !important;
  margin-bottom: 12px !important;
}


button.btn.btn-primary.paginated {
  background-color: transparent !important;
  border: .2px solid var(--main-color) !important;
  color: black !important;
  width: 32px !important;
  height: 32px !important;
  padding: 0 !important;
}

button.paginatedBtn.btn {
  padding: 0 !important;
  width: 32px !important;
  height: 32px !important;
}

button.paginatedBtn.btn svg {
  font-size: 16px !important;
  stroke-width: 1 !important;
}

.table {

  margin: 0 !important;
  margin-top: 20px !important
}

.table_row_image img {
  width: 30px !important;
  height: 30px !important;
}

.pagination p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 5px !important
}


.rowDiv.flex-2-1>div,
.columnDiv {
  padding: 16px !important;
  border-radius: 18px !important;
  box-shadow: 1px 2px 24px -2px #80808029 !important;
}

table tbody tr:nth-child(2n) td,
table thead th.tableHeader {
  background: #F9FBF5 !important;
}

button.paginatedBtn.btn,
.table-container button.btn.btn-primary.paginated {
  background: #f9fbf533 !important;
  border: .1px solid #80808040 !important;
  color: darkgrey;
}

.tableHeader,
.tableCell {
  text-overflow: ellipsis;
  /* overflow: hidden; */
  padding: 8px !important;
  min-width: 150px !important;
  /* max-width: 230px; */
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  text-align: start;
  font-size: 14px !important;
}

.table_row_image p {
  width: fit-content !important
}


.orders-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  /* box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); */
  margin-bottom: 20px;
}

.orders-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orders-title {
  font-size: 1.75rem;
  color: #333;
  font-weight: bold;
}

.orders-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.upload-excel-btn,
.export-excel-btn {
  background-color: #F9FBF5;
  color: #28a745;
  padding: 6px 16px;
  border: 1.5px solid #28a745;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 6px;
}

.upload-excel-btn i,
.export-excel-btn i {
  font-size: 1rem;
  color: #28a745;
}

.upload-excel-btn:hover,
.export-excel-btn:hover {
  background-color: #e9f6e0;
  color: #218838;
  border-color: #218838;
}

.upload-excel-btn:active,
.export-excel-btn:active {
  background-color: #d4edda;
  color: #1e7e34;
  border-color: #1e7e34;
}

.search-container {
  position: relative;
}

.search-icon {
  position: absolute;
  right: 12px;
  /* Adjusted for better alignment */
  top: 50%;
  transform: translateY(-50%);
  color: #28a745;
  font-size: 1rem;
  z-index: 10;
}

.search-input {
  padding: 6px 36px 6px 12px;
  font-size: 0.9rem;
  border: 1.5px solid #ddd;
  border-radius: 6px;
  transition: border-color 0.3s ease;
  min-width: 200px;
}

.search-input:focus {
  outline: none;
  border-color: #28a745;
  box-shadow: 0 0 5px rgba(40, 167, 69, 0.5);
}


/* Form Container */
form {
  display: flex !important;
  flex-direction: column !important;
  gap: 15px !important;
  padding: 20px !important;
  border-radius: 8px !important;
  background-color: transparent !important;
  /* No background color */
  box-shadow: none !important;
  /* No shadow */
  max-width: 600px !important;
  margin: 0 auto !important;
}

/* Input Fields, Selects, and Textarea */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
select,
textarea {
  border: 2px solid #ddd !important;
  border-radius: 4px !important;
  /* padding: 12px !important; */
  font-size: 16px !important;
  width: 100% !important;
  box-sizing: border-box !important;
  transition: border-color 0.3s ease !important;
  background-color: #f7f7f7 !important;
  /* Light background for inputs */
  color: #333 !important;
  /* Dark text color */
}


input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus {
  border-color: var(--main-color) !important;
  background-color: #fff !important;
  /* White background on focus */
  outline: none !important;
}

/* File Input */
input[type="file"] {
  border: 2px solid #ddd !important;
  border-radius: 4px !important;
  /* padding: 8px !important; */
  background-color: #fff !important;
  font-size: 16px !important;
  transition: border-color 0.3s ease !important;
  cursor: pointer !important;
}

input[type="file"]:hover {
  border-color: var(--main-color) !important;
}

/* Textarea */
textarea {
  resize: vertical !important;
  min-height: 120px !important;
  /* Increased min-height */
  max-height: 300px !important;
  border: 2px solid #ddd !important;
  border-radius: 4px !important;
  padding: 12px !important;
  font-size: 16px !important;
  background-color: #f7f7f7 !important;
  /* Light background */
  color: #333 !important;
  /* Dark text color */
  transition: border-color 0.3s ease !important;
}

textarea:focus {
  border-color: var(--main-color) !important;
  background-color: #fff !important;
  /* White background on focus */
}

/* Label for inputs */
label {
  font-size: 14px !important;
  margin-bottom: 8px !important;
  /* Space between label and input */
  font-weight: bold !important;
  color: #555 !important;
  /* Darker grey color for labels */
}


.package-item {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  /* White background for the package item */
  border: 1px solid #ddd;
  /* Light grey border */
  border-radius: 10px;
  /* Rounded corners */
  padding: 20px;
  /* Padding inside the item */
  margin: 15px 0;
  /* Margin between items */
  cursor: pointer;
  /* Pointer cursor on hover */
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  /* Smooth transition for effects */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Light shadow for depth */
  direction: rtl;
  /* Right-to-left direction */
  position: relative;
  /* For gradient overlay positioning */
}

.package-item.active {
  background: green;
  /* Linear gradient background */
  border: none;
  /* Remove border */
  box-shadow: 0 6px 12px rgba(0, 148, 20, 0.3);
  /* Slightly larger shadow for active state */
  transform: scale(1.03);
  color: #ffffff;
  /* Text color for better contrast on gradient */
  /* Slightly enlarge the item when active */
}

.package-item.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #ffffff;
  /* Text color for better contrast on gradient */

  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  /* Slight overlay to make text stand out */
  border-radius: 10px;
  z-index: 1;
}

.package-image {
  width: 60px;
  /* Adjusted image width */
  height: 60px;
  /* Adjusted image height */
  border-radius: 8px;
  /* Rounded corners for image */
  margin-left: 15px;
  /* Space between image and text in RTL */
  position: relative;
  z-index: 2;
  /* Ensure image is above overlay */
}

.package-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* Space between text lines */
  text-align: right;
  /* Right-align text for RTL */
  position: relative;
  z-index: 2;
  /* Ensure text is above overlay */
}

.package-name,
.package-years,
.package-price {
  font-size: 18px;
  /* Font size for text */
  font-weight: 600;
  /* Bold text */
  color: inherit;
  /* Inherit color from parent */
}

.package-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  /* Shadow on hover */
  transform: scale(1.02);
  /* Slightly enlarge on hover */
}


.field_input {
  width: 100% !important;
  position: relative !important;
  margin: 20px 0 !important;
}

.field_input input {
  width: 100% !important;
  padding: 12px 12px 12px 8px !important;
  /* Padding for text input */
  border: 1px solid #ccc !important;
  /* Light grey border */
  border-radius: 5px !important;
  /* Rounded corners */
  font-size: 16px !important;
  /* Font size */
  outline: none !important;
  /* Remove default outline */
  background: transparent !important;
  /* Transparent background to avoid overlapping issues */
  transition: border-color 0.3s ease !important;
  /* Smooth border color transition */
}

.field_input input:focus {
  border-color: var(--main-color) !important;
  /* Change border color on focus */
}

.field_input label {
  position: absolute !important;
  top: -15px !important;
  /* Position above the input */
  background-color: white !important;
  /* Background color for contrast */
  padding: 0 6px !important;
  /* Padding for spacing */
  font-size: 12px !important;
  /* Smaller font size */
  color: var(--main-color) !important;
  /* Change color for visibility */
  border-radius: 5px !important;
  /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  /* Subtle shadow */
  transform: translateY(-50%) !important;
  /* Center vertically */
  pointer-events: none !important;
  /* Prevent label from interfering with clicks */
  transition: all 0.3s ease !important;
  /* Smooth transition */
}

/* Adjust the input and label styles on focus */
.field_input input:focus+label,
.field_input input:not(:placeholder-shown)+label {
  color: var(--main-color) !important;
  /* Change color when input is focused or not empty */
}

form button {
  grid-column: 1 / -1;
  /* Make button span the full width of the form */
  display: inline-block;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background: green;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  /* margin-bottom:20px; */
  text-align: center;
  transition: all 0.3s ease;
}

/* Hover effect for button */
form button:hover {
  background: green;
  transform: scale(1.05);
}

/* Focus effect for button */
form button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}

/* Active effect for button */
form button:active {
  background: green;
  transform: scale(1);
}

/* Disabled button state */
form button:disabled {
  background: #ccc;
  color: #666;
  cursor: not-allowed;
  box-shadow: none;
}

.form-row-group {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
  gap: 10px !important;
  /* Space between grid items */
}

.fieldList-content {
  display: flex;
  gap: 10px !important;
  /* Space between grid items */

}

form {
  /* display: grid !important; */
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
  /* Automatically adjust column widths */
  gap: 15px !important;
  /* Space between grid items */
  padding: 20px !important;
  border-radius: 8px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  margin: 0 auto !important;
  align-items: center !important;
  max-width: 100% !important;
}

.modalContainer form {
  /* height: 85vh !important; */
  max-height: 100vh !important;
  overflow: initial !important;
}


.field_input label[for="add-image"] {
  position: relative !important;
  top: 0 !important;
  box-shadow: none !important;
  transform: none !important;
}