.formcontainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.formcard {
  background-color: #fff; /* Warna latar card */
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: var(--main-color); /* Warna teks pada card */
}

.formcard h2 {
  text-align: center;
}

.formcard form {
  display: flex;
  flex-direction: column;
}

.formcard label {
  margin-bottom: 6px;
}

.formcard input {
  padding: 10px;
  margin-bottom: 12px;
  border: 1px solid var(--main-color); /* Warna border input */
  border-radius: 4px;
  transition: border-color 0.3s ease-in-out;
  outline: none; /* Hapus outline pada saat focus */
}

.formcard input:focus {
  border-color: var(--main-color); /* Warna border input saat focus */
}

.formcard button {
  background-color: var(--main-color); /* Warna latar button */
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  transition: background-color 0.3s ease-in-out;
}

.formcard button:hover {
  background-color: #0094199c; /* Warna latar button saat hover */
}
