.defaultLayout aside {
  min-width: 250px !important;
  width: min(250px, 100%);
  height: 100vh;
  position: sticky;
  overflow-y: auto;
  box-shadow: 1px 2px 24px -2px #80808045;
  transition: 0.5s ease-in-out;
  top: 0;
  z-index: 100 !important;
}

.sidebar ul a,
li.category-header {
  display: flex;
  height: 100%;
  width: 100%;
  font-weight: 800;
  line-height: 65px;
  font-size: 14px;
  color: black;
  padding-left: 40px;
  box-sizing: border-box;
  text-decoration: none;
  /* border-bottom: 1px solid black; */
  /* border-top: 1px solid rgba(255, 255, 255, 0.1); */
  transition: 0.4s;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0 30px;
  width: 100%;
  font-weight: 600;
}

li.category-header {
  color: rgba(42, 40, 40, 0.749) !important;
}

.sidebar ul a.active {
  background-color: var(--main-color);
  color: white;
  font-weight: 900;
}

ul li {
  list-style: none;
}
ul {
  padding: 0 !important;
}
.sidebar ul a i {
  margin-right: 16px;
}
#check {
  display: none;
}
label #btn,
label #cancel {
  position: absolute;
  background: #042331;
  border-radius: 3px;
  cursor: pointer;
}
label #btn {
  left: 40px;
  top: 25px;
  font-size: 35px;
  color: white;
  padding: 6px 12px;
  transition: all 0.5s;
}
label #cancel {
  z-index: 1111;
  left: -195px;
  top: 17px;
  font-size: 30px;
  color: #0a5275;
  padding: 4px 9px;
  transition: all 0.5s ease;
}
#check:checked ~ .sidebar {
  left: 0;
}
#check:checked ~ label #btn {
  left: 250px;
  opacity: 0;
  pointer-events: none;
}
#check:checked ~ label #cancel {
  left: 195px;
}
#check:checked ~ section {
  margin-left: 250px;
}

img.logo {
  height: 90px;
}

img.logo {
  height: 90px;
  width: 150px;
  object-fit: contain;
}

.logoCon {
  margin: 0 auto 20px auto;
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: white;
  top: 0;
  position: sticky;
  z-index: 12;
}

/* .page_padding {
  padding: 90px 30px !important;
} */

/* When Active */
.defaultLayout aside.closed a svg {
  text-align: center !important;
}
.defaultLayout aside.closed a {
  margin-bottom: 20px;
  width: fit-content;
  padding: 20px;
  text-align: center;
  text-align: center !important;
  z-index: 2131321231321321 !important;
}
.defaultLayout aside.closed a span {
  position: absolute;
  right: 100%;
  display: none;
  min-width: 200px;
  background: none;
  z-index: 2131321231321321 !important;
  color: white;
  padding: 0 20px;
  border-radius: 5px;
}

.defaultLayout aside.closed {
  max-width: 80px !important;
  min-width: 80px !important;
}

.defaultLayout aside.closed img.logo {
  display: none;
}

span.menu {
  cursor: pointer;
}

button.btn.btn-primary.paginated {
  background-color: var(--main-color);
}

aside.sidebar ul h5 {
  padding: 5px 30px;
  color: black;
  font-weight: 900;
  border-bottom: 0.1px solid green;
}
