/* StatisticsCharts.css */

.statistics-charts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin: 20px;
  }
  
  .date-filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .chart-container {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    height: 600px;
    overflow: hidden;
  }
  
  .chart-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  }
  
  .chart-title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .react-datepicker-wrapper {
    width: 45%;
  }
  
  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    margin-bottom: 10px;
    transition: border-color 0.3s ease;
  }
  
  .react-datepicker__input-container input:focus {
    border-color: #007bff;
    outline: none;
  }
  