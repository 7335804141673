fieldset {
    border: 2px solid var(--main-color); /* Green border */
    border-radius: 8px; /* Rounded corners */
    padding: 20px 15px 10px 15px; /* Extra padding at the top to accommodate the legend */
    margin: 20px 0; /* Space above and below the fieldset */
    background-color: #f9f9f9; /* Light grey background */
    position: relative; /* Allows positioning of the legend */
  }
  
  legend {
    width:fit-content !important;
    font-size: 1.2em; /* Larger text size */
    font-weight: bold; /* Bold text */
    color: var(--main-color); /* Green text color */
    padding: 0 10px; /* Padding on the left and right of the text */
    border: 2px solid var(--main-color); /* Green border matching the fieldset */
    border-radius: 5px; /* Rounded corners */
    background-color: #ffffff; /* White background */
    position: absolute; /* Positioning the legend absolutely within the fieldset */
    top: -10px; /* Adjust this value to position the legend vertically */
  }
  
  .form-row-group{
    width:100%
  }

  .field_input {
    position: relative;
    margin: 20px 0;
  }
  .field_input .value-indicator {
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    color: red !important;
    padding: 5px;
    box-shadow: 1px 2px 24px -2px darkgrey;
    font-weight: 900;
    border-radius: 50%;
    font-size: 15px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 2;
}

.cosmic-23w {
    background-color: #f4f4f9;
    padding: 20px;
    border-radius: 8px;
  }
  
  .cosmic-form-56g {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .cosmic-input-92d label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .cosmic-table-wrapper-47l {
    margin-top: 20px;
  }
  
  .cosmic-table-89k {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .cosmic-table-89k td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .cosmic-label-03q {
    background-color: #f1f1f1;
    font-weight: bold;
    width: 150px;
  }
  
  .cosmic-data-54x {
    text-align: left;
  }
  
  .cosmic-button-29z {
    background-color: var(--main-color);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .cosmic-button-29z:hover {
    background-color: var(--main-color);
  }
  