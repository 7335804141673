/* Footer.css */
.footer-container {
  background-color: #f8f9fa;
  padding: 1rem;
  direction: rtl; /* Right-to-left layout for Arabic */
  text-align: center;
  border-top: 1px solid #e9ecef;
  position: sticky;
  bottom: 0; 
}

.footer-bottom {
  font-size: 0.875rem;
  color: #6c757d;
  display:flex;
  justify-content: space-between;
  align-items:center
}

.footer-bottom p {
  margin: 0.5rem 0;
}

.footer-bottom span {
  color: #dc3545; /* Red color for the heart emoji */
}
